import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../helpers/Colors";
import { Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  body: {
    height: "100%",
    padding: "8%",
  },
  FourOFour: {
    fontSize: "100px",
    fontFamily: "Frutiger-Normal",
    color: COLORS.secondaryYellow,
    margin: "0px",
  },
  text: {
    fontSize: "16px",
    fontFamily: "Frutiger-Normal",
    color: COLORS.black,
    margin: "0px",
  },
  navlink: {
    textDecoration: "none",
    lineHeight: "48px",
    color: COLORS.white,
    height: "48px",
    margin: "20px",
    borderRadius: "20px",
    background: COLORS.secondaryYellow,
    padding: "5px 10px 5px 10px",
  },
  logo: {
    width: "100%",
    height: "auto",
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      className={classes.body}
    >
      <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{ margin: "20px" }}>
        <img
          className={classes.logo}
          alt="logo"
          src={require("../assets/favicon.png")}
        />
      </Grid>
      <p className={classes.text}> هذه صفحة غير موجودة </p>
      <NavLink to="/" className={classes.navlink}>
        الصفحة الرئيسية
      </NavLink>
    </Grid>
  );
};

export default NotFoundPage;
