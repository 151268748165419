import React, { useContext } from "react";
import { LinearProgress, Grid } from "@material-ui/core";
import { COLORS } from "../helpers/Colors";
import { ScreenSizeContext } from "../contexts/ScreenSizeContext";

const LoadingPage = (props) => {
  const { matchesMD } = useContext(ScreenSizeContext);
  return (
    <Grid
      container
      justify={
        props.followScreenSize
          ? matchesMD
            ? "flex-start"
            : "center"
          : "center"
      }
    >
      <LinearProgress
        style={{
          color: COLORS.secondaryYellow,
          width: props.followScreenSize ? (matchesMD ? "70%" : "90%") : "100%",
          maxWidth: props.maxWidth ? props.maxWidth : "100%",
        }}
      />
    </Grid>
  );
};

export default LoadingPage;
