import React, { useContext } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Chip, Tooltip } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";

import Star from "../../assets/new icons/star.svg";
import StarFilled from "../../assets/new icons/starFilled.svg";
import { translations } from "../../helpers/Translations";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "inherit",
    textDecoration: "none",
    marginBottom: "10px",
    width: "100%",
    maxWidth: "1000px",
  },
  card: {
    backgroundColor: COLORS.whiteSmoke,
    textAlign: "center",
    borderRadius: "20px",
    paddingBottom: "10px",
    paddingTop: "10px",
  },

  name: {
    fontFamily: "Gothic-Normal",
    fontWeight: "900",
    color: COLORS.secondaryYellow,
    fontSize: "16px",
    marginBottom: "0px",
    lineHeight: "1.5",
  },
  desc: {
    fontSize: "14px",
    fontFamily: "Gothic-Normal",
    marginTop: "0px",
    lineHeight: "1.5",
  },
  placeImage: {
    width: "100%",
    height: "auto",
    borderRadius: "20px",
    maxHeight: "120px",
    objectFit: "contain",
  },
  chipLabel: {
    background: COLORS.secondaryYellow,
    color: COLORS.white,
    fontFamily: "Gothic-Normal",
    margin: "5px",
  },
  badge: {
    width: "25%",
    marginTop: "-30px",
  },
  star_feature: {
    width: "27%",
    minWidth: "50px",
  },
}));

let cleanBadge = require("../../assets/new icons/Badge-clean.svg");
let safeBadge = require("../../assets/new icons/Badge-safe.svg");

const contactLess = require("../../assets/new icons/Feature-contactless-icon.svg");
const outdoors = require("../../assets/new icons/Feature-outdoor-icon.svg");
const driveThrough = require("../../assets/new icons/Feature-drivethrough-icon.svg");

const ResultCard = (props) => {
  const classes = useStyles();
  const { matchesSM } = useContext(ScreenSizeContext);

  let to = `id/${props.id}`;
  if (props.name) {
    let nameDashed = props.name.split(" ").join("-");
    to += `/${nameDashed}`;
  }

  return (
    <NavLink className={classes.link} to={to}>
      <Grid
        className={classes.card}
        container
        justify="flex-start"
        alignItems="center"
        style={{ direction: "rtl" }}
        direction={!matchesSM ? "column" : "row"}
      >
        <Grid item xs={7} sm={3} md={2} lg={2} xl={2} style={{ margin: "5px" }}>
          {props.image ? (
            <img
              className={classes.placeImage}
              alt={props.name}
              src={props.image}
            />
          ) : (
            <img
              className={classes.placeImage}
              alt={props.name}
              src={require("../../assets/favicon.png")}
            />
          )}
          <div>
            {props.clean ? (
              <img
                className={classes.badge}
                src={cleanBadge}
                alt="Clean Bade"
              />
            ) : null}
            {props.safe ? (
              <img className={classes.badge} src={safeBadge} alt="Safe Badge" />
            ) : null}
          </div>
        </Grid>
        <Grid
          item
          xs={9}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{
            margin: "10px",
            textAlign: !matchesSM ? "center" : "right",
          }}
        >
          <p className={classes.name}>{props.name}</p>
          <p className={classes.desc}> {props.address} </p>
          {props.gov && (
            <Chip
              className={classes.chipLabel}
              size="small"
              label={
                translations.translations[props.gov]
                  ? translations.translations[props.gov]
                  : props.gov
              }
            />
          )}
          {props.type && (
            <Chip
              className={classes.chipLabel}
              size="small"
              label={props.type}
            />
          )}
        </Grid>

        <Grid
          item
          xs={7}
          sm={3}
          md={3}
          lg={2}
          xl={2}
          style={{
            marginRight: !matchesSM ? "0px" : "auto",
            marginLeft: !matchesSM ? "0px" : "5px",
          }}
        >
          <div>
            <img
              src={props.score >= 1 ? StarFilled : Star}
              className={classes.star_feature}
            />
            <img
              src={props.score >= 2 ? StarFilled : Star}
              className={classes.star_feature}
            />
            <img
              src={props.score >= 3 ? StarFilled : Star}
              className={classes.star_feature}
            />
          </div>
          <div>
            {props.outdoor && (
              <Tooltip title="Outdoors Badge">
                <img
                  className={classes.star_feature}
                  src={outdoors}
                  alt="outdoors"
                />
              </Tooltip>
            )}
            {props.drivethrough && (
              <Tooltip title="Drivethrough Badge">
                <img
                  className={classes.star_feature}
                  src={driveThrough}
                  alt="drive-through"
                />
              </Tooltip>
            )}
            {props.contactless && (
              <Tooltip title="Contactless Badge">
                <img
                  className={classes.star_feature}
                  src={contactLess}
                  alt="contact-less"
                />
              </Tooltip>
            )}
          </div>
        </Grid>
      </Grid>
    </NavLink>
  );
};

export default ResultCard;
