import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "@material-ui/icons/Copyright";

import { COLORS } from "../helpers/Colors";
import SocialMedia from "./Header/SocialMedia";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: COLORS.footerBg,
    minHeight: "60px",
    textAlign: "center",
    padding: "5px",
  },
  copyRight: {
    marginTop: "5px",
    marginLeft: "3px",
    color: COLORS.black,
    fontSize: "14px",
    width: "100%",
    display: "inline",
  },
  copyRightIcon: {
    color: COLORS.secondaryYellow,
    display: "inline-flex",
    marginBottom: "-6px",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <Copyright className={classes.copyRightIcon} />
      <div className={classes.copyRight}>
        Produced by International Alert - Tunisia, developed by Shamseya for
        Innovative Community Health Care Solutions, funded by the UK government
      </div>
      <Grid item xs={12}>
        <SocialMedia />
      </Grid>
    </div>
  );
};

export default Footer;
