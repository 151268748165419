import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import { Grid, Tooltip } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";
import { Chip } from "@material-ui/core";

import Star from "../../assets/new icons/star.svg";
import StarFilled from "../../assets/new icons/starFilled.svg";
import { translations } from "../../helpers/Translations";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "30px",
    backgroundColor: COLORS.whiteSmoke,
    boxShadow: "0px 3px 3px 0px #aaaaaa",
    overflow: "hidden",
    minHeight: "95px",
  },
  name: {
    color: COLORS.secondaryYellow,
    fontSize: "16px",
    fontFamily: "Frutiger-Normal",
    fontWeight: "900",
    margin: "5px",
  },

  link: {
    color: "inherit",
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "20px",
    maxHeight: "93px",
    objectFit: "contain",
  },
  chipLabel: {
    background: COLORS.secondaryYellow,
    color: COLORS.white,
    margin: "5px",
    fontFamily: "Frutiger-Normal",
    textTransform: "capitalize",
  },
  star_feature: {
    width: "27%",
    minWidth: "50px",
  },
}));
const contactLess = require("../../assets/new icons/Feature-contactless-icon.svg");
const outdoors = require("../../assets/new icons/Feature-outdoor-icon.svg");
const driveThrough = require("../../assets/new icons/Feature-drivethrough-icon.svg");

const SummaryCard = (props) => {
  const classes = useStyles();

  const { matchesSM } = useContext(ScreenSizeContext);

  let to = `id/${props.id}`;
  if (props.name) {
    let nameDashed = props.name.split(" ").join("-");
    to += `/${nameDashed}`;
  }

  return (
    <NavLink className={classes.link} to={to}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        className={classes.card}
        style={{
          direction: "rtl",
          padding: "5px 0px",
        }}
        direction={!matchesSM ? "column" : "row"}
      >
        {/* image */}
        <Grid item xs={5} sm={3} md={2} lg={2} xl={2} style={{ margin: "5px" }}>
          {props.image ? (
            <img
              className={classes.image}
              alt={props.name}
              src={props.image.url}
            />
          ) : (
            <img
              className={classes.image}
              alt={props[`place_name`]}
              src={require("../../assets/favicon.png")}
            />
          )}
        </Grid>
        {/* Chips for name and gov */}
        <Grid
          item
          xs={9}
          sm={5}
          md={5}
          lg={5}
          xl={6}
          style={{
            margin: "5px",
            textAlign: !matchesSM ? "center" : "inherit",
          }}
        >
          <p className={classes.name}>{props.name}</p>
          {props.gov && (
            <Chip
              className={classes.chipLabel}
              size="small"
              label={props.gov}
            />
          )}
          {props.type && (
            <Chip
              className={classes.chipLabel}
              size="small"
              label={
                translations.translations[props.type]
                  ? translations.translations[props.type]
                  : props.type
              }
            />
          )}
        </Grid>
        <Grid
          item
          xs={7}
          sm={3}
          md={4}
          lg={4}
          xl={3}
          style={{
            marginRight: !matchesSM ? "0px" : "auto",
            marginLeft: "5px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={props.score >= 1 ? StarFilled : Star}
              className={classes.star_feature}
            />
            <img
              src={props.score >= 2 ? StarFilled : Star}
              className={classes.star_feature}
            />
            <img
              src={props.score >= 3 ? StarFilled : Star}
              className={classes.star_feature}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            {props.outdoor && (
              <Tooltip title="Outdoors Badge">
                <img
                  className={classes.star_feature}
                  src={outdoors}
                  alt="outdoors"
                />
              </Tooltip>
            )}
            {props.drivethrough && (
              <Tooltip title="Drivethrough Badge">
                <img
                  className={classes.star_feature}
                  src={driveThrough}
                  alt="drive-through"
                />
              </Tooltip>
            )}

            {props.contactless && (
              <Tooltip title="Contactless Badge">
                <img
                  className={classes.star_feature}
                  src={contactLess}
                  alt="contact-less"
                />
              </Tooltip>
            )}
          </div>
        </Grid>
      </Grid>
    </NavLink>
  );
};

export default SummaryCard;
