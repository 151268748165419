import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../helpers/Colors";
import { Grid } from "@material-ui/core";
import Circle from "@material-ui/icons/FiberManualRecord";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    textAlign: "center",
    width: "100%",
    margin: "5px",
  },
  circle: {
    width: "100%",
    borderRadius: "50%",
    paddingTop: "100%",
    position: "relative",
    backgroundColor: COLORS.white,
  },
  title: {
    fontFamily: "Frutiger-Normal",
    fontSize: "16px",
    marginTop: "20px",
    display: "block",
    fontWeight: "700",
  },
  label: {
    fontFamily: "Frutiger-Normal",
    fontSize: "14px",
    display: "block",
    padding: "0px",
    margin: "10px",
    listStylePosition: "outside",
  },
  text: {
    height: "200px",
  },
  image: {
    width: "70px",
    height: "auto",
    position: "absolute",
    marginTop: "-100%",
  },
  scoreBox: {
    width: "95px",
    height: "40px",
    lineHeight: "40px",
    borderRadius: "10px",
    color: COLORS.white,
    fontSize: "16px",
    fontFamily: "Frutiger-Normal",
    marginTop: "-20px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    backgroundColor: COLORS.neutralYellow,
  },
  lsistItemIcon: {
    color: COLORS.black,
    fontSize: "0.7rem",
  },
}));

const Standard = (props) => {
  const classes = useStyles();

  const color =
    props.attributesMaybe.length > 0 ||
    (props.attributesYes.length > 0 && props.attributesNo.length > 0)
      ? COLORS.neutralYellow
      : props.attributesYes.length === 0 &&
        props.attributesMaybe.length === 0 &&
        props.attributesNo.length > 0
      ? COLORS.failRed
      : props.attributesNo.length === 0 &&
        props.attributesMaybe.length === 0 &&
        props.attributesYes.length > 0
      ? COLORS.successGreen
      : COLORS.successGreen;

  return (
    <Grid container justify="center" item xs={12} sm={4} md={3} lg={2} xl={2}>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.body}
      >
        <div style={{ maxWidth: "250px", width: "100%" }}>
          <Grid
            container
            style={{
              minHeight: "180px",
              flexWrap: "nowrap",
              position: "relative",
              minHeight: "180px",
            }}
          >
            <Circle
              style={{
                color: color,
                position: "absolute",
                top: "45%",
                width: "10px",
              }}
            />
            <img
              alt={props.title}
              src={props.image}
              style={{
                background: "green",
                borderRadius: "50%",
                backgroundColor: color,
                margin: "auto",
                width: "60%",
              }}
            />
            <Circle
              style={{
                color: color,
                position: "absolute",
                left: "0",
                top: "45%",
                width: "10px",
              }}
            />
          </Grid>

          <p className={classes.title}> {props.title} </p>
          <List
            dense={true}
            style={{
              minHeight: "130px",
              direction: "rtl",
              width: "95%",
              marginRight: "inherit",
            }}
          >
            {props.mappings &&
              props.attributesYes.map((attributeYes) => (
                <ListItem
                  style={{
                    textAlign: "center",
                    display: "flex",
                    margin: "0px",
                  }}
                  key={attributeYes}
                  className={classes.label}
                >
                  <ListItemIcon style={{ minWidth: "18px" }}>
                    <Circle className={classes.lsistItemIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    primary={props.mappings[attributeYes]}
                  />
                </ListItem>
              ))}

            {props.mappings &&
              props.attributesMaybe.map((attributeMaybe) => (
                <ListItem
                  style={{ textAlign: "center", display: "flex" }}
                  key={attributeMaybe}
                  className={classes.label}
                >
                  <ListItemIcon style={{ minWidth: "18px" }}>
                    <Circle className={classes.lsistItemIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    primary={`${props.mappings[attributeMaybe]} أحياناً`}
                  />
                </ListItem>
              ))}
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

export default Standard;
