import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";

import SummaryCard from "./SummaryCard";
import { COLORS } from "../../helpers/Colors";
import { APIContext } from "../../contexts/APIContext";
import { translations } from "../../helpers/Translations";

const useStyles = makeStyles((theme) => ({
  body: {
    direction: "rtl",
    margin: "50px",
  },
  title: {
    fontFamily: "Frutiger-Normal",
    fontWeight: "900",
    fontSize: "22px",
    fontColor: COLORS.black,
    marginRight: "40px",
    marginLeft: "40px",
  },
  showMore: {
    color: COLORS.secondaryYellow,
    fontSize: "22px",
    textDecoration: "none",
    borderBottom: "2px solid",
    borderColor: COLORS.secondaryYellow,
    fontFamily: "Frutiger-Normal",
    fontWeight: "900",
    textTransform: "none",
  },
}));

const LastReviewedPart = () => {
  const classes = useStyles();
  const [places, setPlaces] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);

  const { getRecentReviews, API_URL } = useContext(APIContext);

  useEffect(() => {
    let newPlaces = [];
    getRecentReviews(currPage).then((response) => {
      setPagesCount(parseInt(response.totalPages));
      if (currPage === 1) {
        newPlaces = response.places;
      } else {
        newPlaces = [...places, ...response.places];
      }
      setPlaces(newPlaces);
    });
  }, [currPage]);

  useEffect(() => {
    let newPlaces = [];
    if (currPage === 1) {
      getRecentReviews(1).then((response) => {
        setPagesCount(parseInt(response.totalPages));
        newPlaces = response.places;
        setPlaces(newPlaces);
      });
    } else {
      setCurrPage(1);
    }
  }, [API_URL]);

  const handleChangePage = () => {
    setCurrPage(currPage + 1);
  };

  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {places.length > 0 ? (
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          item
          xs={12}
          s={12}
          md={12}
          lg={11}
          xl={11}
        >
          <p className={classes.title}>{translations.statics.lastReviewed}</p>
          <Grid container direction="row" justify="space-evenly">
            {places.map((place, index) => (
              <Grid
                key={index + currPage}
                item
                xs={12}
                s={12}
                md={6}
                lg={5}
                xl={5}
                style={{ margin: "15px" }}
              >
                <SummaryCard
                  id={place.id}
                  name={place.acf[`place_name`]}
                  description={place.acf.description}
                  gov={place.acf[`gov`]}
                  telephone={place.acf["telephone_number"]}
                  type={place.acf[`type_select_tag`][0]}
                  score={parseInt(place.acf.score)}
                  image={place.acf.image}
                  outdoor={place.acf.outdoor}
                  contactless={place.acf.contactless}
                  drivethrough={place.acf.drivethrough}
                />
              </Grid>
            ))}
          </Grid>
          <div
            style={{
              width: "90%",
              textAlign: "left",
              margin: "15px",
            }}
          >
            <Button
              className={classes.showMore}
              onClick={handleChangePage}
              disabled={currPage === pagesCount}
            >
              {translations.statics.showMore}
            </Button>
          </div>
        </Grid>
      ) : null}
    </div>
  );
};

export default LastReviewedPart;
