import React from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  oval: {
    background: "yellow",
    backgroundColor: COLORS.white,
    fontFamily: "Frutiger-Normal",
    fontWeight: "900",
    fontSize: "16px",
    height: "50px",
    textAlign: "center",
    overflow: "hidden",
    lineBreak: "word-break",
    textTransform: "none",
    lineHeight: "1.3",
  },
}));

const TextinOval = (props) => {
  const classes = useStyles();
  let styles = {};
  styles.width = props.width;
  props.borderedRadius === "all"
    ? (styles.borderRadius = "20px")
    : props.borderedRadius === "right"
    ? (styles.borderRadius = "2px 20px 20px 2px")
    : props.borderedRadius === "between"
    ? (styles.borderRadius = "2px 2px 2px 2px")
    : props.borderedRadius === "left"
    ? (styles.borderRadius = "20px 2px 2px 20px")
    : (styles.borderRadius = "2px 2px 2px 2px");

  props.bordered === "right"
    ? (styles.borderLeft = "2px solid black")
    : props.bordered === "left"
    ? (styles.borderRight = "2px solid black")
    : (styles.border = "2px 2px 2px 0px ");

  props.clickable === true
    ? (styles.lineHeight = "1.3")
    : (styles.lineHeight = "50px");

  styles.color = props.color;
  styles.fontWeight = props.fontWeight ? props.fontWeight : "500";
  styles.marginRight = props.marginRight;

  return (
    <div>
      {props.clickable === true ? (
        <Button
          className={classes.oval}
          style={styles}
          onClick={props.selectFilter}
        >
          {props.text}
        </Button>
      ) : (
        <div className={classes.oval} style={styles}>
          {props.text}
        </div>
      )}
    </div>
  );
};

export default TextinOval;
