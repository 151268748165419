export const translations = {
  translations: {
    // Types
    "سوبر ماركت": "سوبر ماركت",
    "مكان خدمات": "مكان خدمات",
    عيادة: "عيادة",
    صيدلية: "صيدلية",
    بنك: "بنك",
    "مركز طبي": "مركز طبي",
    مطبعة: "مطبعة",
    مخبز: "مخبز",
    "عيادة خاصة": "عيادة خاصة",
    عطارة: "عطارة",
    "مركز تجاري": "مركز تجاري",
    محل: "محل",
    "مركز خدمات": "مركز خدمات",
    "محل حلاقة": "محل حلاقة",
    مكتبة: "مكتبة",
    مطعم: "مطعم",
    كافيه: "كافيه",
    سنترال: "سنترال",
    "مكان عمل": "مكان عمل",

    // gov
    القاهرة: "القاهرة",
    الجيزة: " الجيزة",
    الإسكندرية: "الإسكندرية",
    القليوبية: "القليوبية",
    الغربية: "الغربية",
    "البحر الأحمر": "البحر الأحمر",

    // areas
    "التجمع الخامس": "التجمع الخامس",
    "مدينة الشروق": "مدينة الشروق",
    "مصر الجديدة": "مصر الجديدة",
    الزمالك: "الزمالك",
    المعادي: "المعادي",
    شبرا: "شبرا",
    العبور: "العبور",
    العباسية: "العباسية",
    "عين شمس": "عين شمس",
    القطامية: "القطامية",
    المقطم: "المقطم",
    الرحاب: "الرحاب",
    "وسط البلد": "وسط البلد",
    الأزهر: "الأزهر",
    "السيدة زينب": "السيدة زينب",
    "جاردن سيتي": "جاردن سيتي",
    "جسر السويس": "جسر السويس",
    هليوبوليس: "هليوبوليس",
    حلوان: "حلوان",
    امبابة: "امبابة",
    مدينتي: "مدينتي",
    المنيل: "المنيل",
    "مساكن الشيراتون": "مساكن الشيراتون",
    "مصر القديمة": " مصر القديمة",
    "مدينة نصر": "مدينة نصر",
    "النزهة الجديدة": "النزهة الجديدة",
    "القاهرة الجديدة": "القاهرة الجديدة",
    "ابو رواش": "ابو رواش",
    "شيخ زايد": "شيخ زايد",
    مهندسيين: "مهندسيين",
    هرم: "هرم",
    فيصل: "فيصل",
    دقي: "دقي",
    العجوزة: "العجوزة",
    "6 اكتوبر": "6 اكتوبر",
    جيزة: "جيزة",
    المنتزة: "المنتزة",
    "سيدي بشر قبلي": "سيدي بشر قبلي",
    "سيدي جابر": "سيدي جابر",
    الرمل: "الرمل",
    جليم: "جليم",
    "شبرا الخيمة": "شبرا الخيمة",
    طنطا: "طنطا",

    ///// Side nav in search results
    "نوع المكان": "نوع المكان",
    المحافظة: "الالولاية",
    المنطقة: "البلدية",
  },
  statics: {
    addAReview: "شارك بتقييم مكان",
    ///// place
    scoreBoardTop: "المكان حقق",
    scoreBoardBottom: "من معايير مكافحة العدوى",
    googleMaps: "الاتجاهات علي Google Maps",
    participation: " زوار قام بالمشاركة في هذا التقييم ",

    // standards
    infContStand: "معايير مكافحة العدوى",
    lastReview: "اخر مراجعة لتقييمات الزائرين: ",
    temp: "يتم قياس حرارة الزائرين قبل الدخول",
    nodoors: "يمكن دخول المكان دون لمس أبواب أو مقابض",
    airation: "التهوية جيدة",
    crowd: "لا يوجد تكدس أو إزدحام",
    surfaces: "الأسطح نظيفة ويتم تعقيمها بإستمرار",
    sanitizers: "الصابون ومعقم اليدين متوفرين ويسهل الوصول إليهم",
    workers: "العاملون ملتزمون باجراءات مكافحة العدوى ",
    reviewStatement:
      "هذا التقييم تم بناءًا على مؤشرات حددها فريق مؤسسة شمسية لإدارة الأنظمة الصحية وفقًا لبحث شمل الاجراءات الدولية وتطلعات مستخدمي الخدمات العامة. النتائج المعلنة تعبر عن وجهة نظر المقيمين وقت تقييمهم للأماكن المختلفة وبناءًا على تجاربهم الشخصية. يحق لإدارة الأماكن المختلفة طلب تقييم جديد في حالة اختلاف النتائج عن الواقع.",
    viewMore: "إضغط هنا لمعرفة المزيد",

    // New Standards
    cleanliness: "التنظيف والتطهير ",
    entrance_test: "التقييم عند الدخول",
    busy: "التكدس والمساحات",
    air: "التهوية",
    sanitize: "المطهرات",
    personal_protect: "الوقاية الشخصية",
    extraProcedures: "الاجرائات الإضافية",

    //////// Search Results page
    //////// Navigations
    guide: "دليلك",
    search: "ابحث",
    lang: "اللغات",
    coronaRamdan: "رمضانXكورونا",

    egHospitalsStatement: " لتقييم المستشفيات ",
    gov: "الولاية",
    noSearchResults:
      "لا يوجد نتائج لهذا البحث، جرب كلمة بحث أخرى أو تصفيات أخرى",
    searchResults: "نتائج البحث",
    ///// Home
    showMore: "المزيد",
    go: "اذهب",
    lastReviewed: "آخر ما تم تقييمه",

    // CoronaXRamada
    download: "تحميل الملف",
    page: "صفحة",
    of: "من",
  },
};
