import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../helpers/Colors";

const useStyles = makeStyles(() => ({
  navButton: {
    textAlign: "center",
    marginLeft: "10px",
    minWidth: "60px",
  },
  imageContainer: {
    margin: "auto",
  },

  navIcon: {
    width: "30px",
    height: "40px",
  },
  link: {
    marginRight: "auto",
    marginLeft: "auto",
    textDecoration: "none",
    "&.active": {
      "& p": {
        color: COLORS.secondaryYellow,
      },
    },
  },
  label: {
    marginRight: "auto",
    marginLeft: "auto",
    fontFamily: "Frutiger-Normal",
    fontSize: "14px",
    margin: "0px",
    color: COLORS.black,
  },
}));

const LabeledIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.navButton}>
      <NavLink
        to={props.to}
        activeClassName={"active"}
        className={classes.link}
      >
        <div className={classes.imageContainer}>
          <img src={props.icon} className={classes.navIcon} alt={props.label} />
        </div>
        <p className={classes.label}>{props.label}</p>
      </NavLink>
    </div>
  );
};

export default LabeledIcon;
