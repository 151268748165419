import React, { useState, useEffect, useContext } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    height: "240px",
    backgroundColor: COLORS.whiteSmoke,
    borderRadius: "20px",
    textAlign: "center",
    margin: "0px",
    marginTop: "20px",
    maxWidth: "400px",
  },
}));
const GoogleMapsPlugin = ({ long, lat, placeName }) => {
  const classes = useStyles();
  const [src, setsrc] = useState("");
  useEffect(() => {
    let currsrc = "";
    if (long && lat) {
      currsrc =
        "https://maps.google.com/maps?q=" +
        lat +
        "," +
        long +
        "&hl=es;z=14&amp;&output=embed";
    }
    setsrc(currsrc);
  }, [long, lat]);
  return (
    <div className={classes.card}>
      <iframe
        title={placeName}
        src={src}
        style={{ borderRadius: "20px" }}
        width="100%"
        height="240px"
        frameBorder="0"
      />
    </div>
  );
};

export default GoogleMapsPlugin;
