import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { SearchContext } from "../../contexts/SearchContext";

import LoadingPage from "../LoadingPage";
import { translations } from "../../helpers/Translations";
const useStyles = makeStyles((theme) => ({
  body: {
    width: "100%",
    marginTop: "40px",
    height: "fit-content",
    borderRadius: "20px",
    backgroundColor: COLORS.whiteWhisperer,
    direction: "rtl",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    padding: "5px",
  },
  navlink: {
    textDecoration: "none",
    lineHeight: "48px",
    color: COLORS.white,
    height: "40px",
    margin: "0px",
    borderRadius: "20px",
    backgroundColor: COLORS.secondaryYellow,
    padding: "8px",
  },
  chipLabel: {
    textTransform: "capitalize",
    fontFamily: "Frutiger-Normal",
  },
}));

const FiltersForMobile = (props) => {
  const classes = useStyles();
  const {
    comboFiltersPicked,
    setComboFiltersPicked,
    filterData,
    govAreaMappings,
  } = useContext(SearchContext);

  // determining which filter clicked
  const filterClick = (id, row) => {
    let newCombo = [...comboFiltersPicked];
    if (newCombo[row].includes(id)) {
      // TO-DO remove this gov areas from areas options and areas picked
      newCombo[row] = newCombo[row].filter((record) => record !== id);
      if (row === 1) {
        let removedGovAreas = govAreaMappings[id];
        let newPickedAreas = [...newCombo[2]];

        newCombo[2].forEach((areaPicked) => {
          removedGovAreas.forEach((removedArea) => {
            if (removedArea.id === areaPicked) {
              newPickedAreas = newPickedAreas.filter(
                (area) => area !== areaPicked
              );
            }
          });
        });
        newCombo[2] = newPickedAreas;
      }
    } else {
      newCombo[row].push(id);
    }
    setComboFiltersPicked(newCombo);
  };

  const loadChips = () => {
    let filterRow = filterData[1];
    let rowIndex = 1;
    return (
      <span>
        <div className={classes.root}>
          {filterRow.options.map((option, i) => {
            if (i < 3) {
              return (
                <Chip
                  className={classes.chipLabel}
                  key={option.name}
                  size="medium"
                  label={
                    translations.translations[option.name]
                      ? translations.translations[option.name]
                      : option.name
                  }
                  style={{
                    background: comboFiltersPicked[rowIndex].includes(option.id)
                      ? COLORS.secondaryYellow
                      : COLORS.lightGray,
                    color: comboFiltersPicked[rowIndex].includes(option.id)
                      ? COLORS.white
                      : COLORS.black,
                  }}
                  onClick={() => filterClick(option.id, rowIndex)}
                />
              );
            }
          })}
        </div>
      </span>
    );
  };
  return (
    <div className={classes.body}>
      {filterData.length === 0 ? (
        <LoadingPage followScreenSize={true} maxWidth="1000px" />
      ) : (
        <span>
          <div className={classes.root}>
            <Chip
              className={classes.chipLabel}
              size="medium"
              label="مغازة"
              style={{
                background: comboFiltersPicked[0].includes(139)
                  ? COLORS.secondaryYellow
                  : COLORS.lightGray,
                color: comboFiltersPicked[0].includes(139)
                  ? COLORS.white
                  : COLORS.black,
              }}
              onClick={() => filterClick(139, 0)}
            />
            <Chip
              className={classes.chipLabel}
              size="medium"
              label="صيدلية"
              style={{
                background: comboFiltersPicked[0].includes(142)
                  ? COLORS.secondaryYellow
                  : COLORS.lightGray,
                color: comboFiltersPicked[0].includes(142)
                  ? COLORS.white
                  : COLORS.black,
              }}
              onClick={() => filterClick(142, 0)}
            />
            <Chip
              className={classes.chipLabel}
              size="medium"
              label="بنك"
              style={{
                background: comboFiltersPicked[0].includes(149)
                  ? COLORS.secondaryYellow
                  : COLORS.lightGray,
                color: comboFiltersPicked[0].includes(149)
                  ? COLORS.white
                  : COLORS.black,
              }}
              onClick={() => filterClick(149, 0)}
            />
          </div>

          {loadChips()}

          <NavLink to="/search" className={classes.navlink}>
            {translations.statics["showMore"]}
          </NavLink>
        </span>
      )}
    </div>
  );
};

export default FiltersForMobile;
