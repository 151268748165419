import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import TextinOval from "./TextinOval";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { SearchContext } from "../../contexts/SearchContext";
import { translations } from "../../helpers/Translations";

const useStyles = makeStyles((theme) => ({
  body: {
    width: "920px",
    minHeight: "280px",
    background: "yellow",
    borderRadius: "20px",
    backgroundColor: COLORS.whiteWhisper,
    padding: "15px",
    marginTop: "5vh",
  },
  searchBtn: {
    backgroundColor: COLORS.secondaryYellow,
    color: COLORS.white,
    width: "100px",
    height: "48px",
    fontFamily: "Frutiger-Normal",
    borderRadius: "20px",
    textTransform: "none",
    marginTop: "20px",
    padding: "0px",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: COLORS.secondaryYellowHover,
    },
    "&:disabled": {
      backgroundColor: COLORS.secondaryYellowDisabled,
      color: COLORS.disabledGray,
    },
  },

  navlink: {
    textDecoration: "none",
    lineHeight: "48px",
    color: "inherit",
    height: "48px",
    margin: "0px",
    borderRadius: "20px",
  },
}));

const Filters = (props) => {
  const classes = useStyles();
  const [shownFilters, setShownFilters] = useState([]);
  const [shownFiltersStart, setShownFiltersStart] = useState([0, 0, 0, 0]);
  const [data, setData] = useState([]);
  const {
    comboFiltersPicked,
    setComboFiltersPicked,
    filterData,
    govAreaMappings,
  } = useContext(SearchContext);

  useEffect(() => {
    if (filterData.length === 0) {
      return;
    }
    let newShownFilters = [];
    let propsData = JSON.parse(JSON.stringify(filterData));
    propsData.forEach((record, i) => {
      newShownFilters.push(
        record.options.slice(shownFiltersStart[i], shownFiltersStart[i] + 4)
      );
    });
    setShownFilters(newShownFilters);
    setData(propsData);
  }, [filterData]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    let newShownFilters = [];
    data.forEach((record, i) => {
      newShownFilters.push(
        record.options.slice(shownFiltersStart[i], shownFiltersStart[i] + 4)
      );
    });
    setShownFilters(newShownFilters);
  }, [shownFiltersStart]);

  // adding/removing 1 from the start index of the shown filters
  const arrowClick = (index, direction) => {
    let newShownFiltersStart = [...shownFiltersStart];
    if (direction === 0) {
      // Left
      if (newShownFiltersStart[index] + 5 <= data[index].options.length) {
        newShownFiltersStart[index] += 1;
      }
    } else {
      // Right
      if (newShownFiltersStart[index] - 1 >= 0) {
        newShownFiltersStart[index] -= 1;
      }
    }
    setShownFiltersStart([...newShownFiltersStart]);
  };

  // determining which filter clicked
  const filterClick = (id, row) => {
    let newCombo = [...comboFiltersPicked];
    if (newCombo[row].includes(id)) {
      // TO-DO remove this gov areas from areas options and areas picked
      newCombo[row] = newCombo[row].filter((record) => record !== id);
      if (row === 1) {
        let removedGovAreas = govAreaMappings[id];
        let newPickedAreas = [...newCombo[2]];

        newCombo[2].forEach((areaPicked) => {
          removedGovAreas.forEach((removedArea) => {
            if (removedArea.id === areaPicked) {
              newPickedAreas = newPickedAreas.filter(
                (area) => area !== areaPicked
              );
            }
          });
        });
        newCombo[2] = newPickedAreas;
      }
    } else {
      newCombo[row].push(id);
    }
    setComboFiltersPicked(newCombo);
  };

  return (
    <div
      className={classes.body}
      style={{
        marginTop: props.marginTop,
        direction: "rtl",
        background:
          filterData.length === 0 ? "transparent" : COLORS.whiteWhisper,
      }}
    >
      <Grid container direction="row" justify="center">
        {shownFilters.length > 0 && data.length > 0
          ? shownFilters.map((filterRow, i) => {
              return (
                <div
                  key={i}
                  style={{ display: "inline-flex", marginTop: "20px" }}
                >
                  {/* Filter title */}
                  <TextinOval
                    text={data[i].title}
                    borderedRadius="all"
                    width="200px"
                    color={COLORS.secondaryYellow}
                    fontWeight="900"
                  />
                  {/* To left arrow */}
                  {/* clickable or not if filters > 4 */}
                  {data[i].options !== null && data[i].options.length > 4 ? (
                    <ArrowRight
                      onClick={() => arrowClick(i, 1)}
                      style={{
                        width: "45px",
                        height: "45px",
                        color:
                          shownFiltersStart[i] - 1 >= 0
                            ? COLORS.black
                            : COLORS.disabledGray,
                        cursor:
                          shownFiltersStart[i] - 1 >= 0 ? "pointer" : "default",
                      }}
                    />
                  ) : (
                    <div style={{ width: "45px" }}></div>
                  )}
                  <TextinOval
                    clickable={true}
                    text={
                      filterRow[0] && filterRow[0].name !== undefined
                        ? translations.translations[filterRow[0].name]
                          ? translations.translations[filterRow[0].name]
                          : filterRow[0].name
                        : ""
                    }
                    bordered="right"
                    borderedRadius="right"
                    width="160px"
                    color={
                      filterRow[0] && filterRow[0].id
                        ? comboFiltersPicked[i].includes(filterRow[0].id)
                          ? COLORS.secondaryYellow
                          : COLORS.black
                        : COLORS.black
                    }
                    selectFilter={
                      filterRow[0]
                        ? () => filterClick(filterRow[0].id, i)
                        : null
                    }
                    fontFamily="Frutiger-Normal"
                    marginRight="0px"
                  />
                  <TextinOval
                    clickable={true}
                    text={
                      filterRow[1] && filterRow[1].name !== undefined
                        ? translations.translations[filterRow[1].name]
                          ? translations.translations[filterRow[1].name]
                          : filterRow[1].name
                        : ""
                    }
                    bordered="between"
                    borderedRadius="between"
                    width="160px"
                    color={
                      filterRow[1] && filterRow[1].id
                        ? comboFiltersPicked[i].includes(filterRow[1].id)
                          ? COLORS.secondaryYellow
                          : COLORS.black
                        : COLORS.black
                    }
                    fontFamily="Frutiger-Normal"
                    selectFilter={
                      filterRow[1]
                        ? () => filterClick(filterRow[1].id, i)
                        : null
                    }
                  />
                  <TextinOval
                    clickable={true}
                    text={
                      filterRow[2] && filterRow[2].name !== undefined
                        ? translations.translations[filterRow[2].name]
                          ? translations.translations[filterRow[2].name]
                          : filterRow[2].name
                        : ""
                    }
                    bordered="left"
                    width="160px"
                    color={
                      filterRow[2] && filterRow[2].id
                        ? comboFiltersPicked[i].includes(filterRow[2].id)
                          ? COLORS.secondaryYellow
                          : COLORS.black
                        : COLORS.black
                    }
                    fontFamily="Frutiger-Normal"
                    selectFilter={
                      filterRow[2]
                        ? () => filterClick(filterRow[2].id, i)
                        : null
                    }
                  />
                  <TextinOval
                    clickable={true}
                    text={
                      filterRow[3] && filterRow[3].name !== undefined
                        ? translations.translations[filterRow[3].name]
                          ? translations.translations[filterRow[3].name]
                          : filterRow[3].name
                        : ""
                    }
                    bordered="left"
                    borderedRadius="left"
                    width="160px"
                    color={
                      filterRow[3] && filterRow[3].id
                        ? comboFiltersPicked[i].includes(filterRow[3].id)
                          ? COLORS.secondaryYellow
                          : COLORS.black
                        : COLORS.black
                    }
                    fontFamily="Frutiger-Normal"
                    selectFilter={
                      filterRow[3]
                        ? () => filterClick(filterRow[3].id, i)
                        : null
                    }
                  />
                  {/* To right arrow */}
                  {/* clickable or not if filters > 4 */}

                  {data[i].options !== null && data[i].options.length > 4 ? (
                    <ArrowLeft
                      onClick={() => arrowClick(i, 0)}
                      style={{
                        width: "45px",
                        height: "45px",
                        color:
                          shownFiltersStart[i] + 5 <= data[i].options.length
                            ? COLORS.black
                            : COLORS.disabledGray,
                        cursor:
                          shownFiltersStart[i] + 5 <= data[i].options.length
                            ? "pointer"
                            : "default",
                      }}
                    />
                  ) : (
                    <div style={{ width: "45px" }}></div>
                  )}
                </div>
              );
            })
          : null}

        {shownFilters.length > 0 && data.length > 0 ? (
          <Button
            variant="contained"
            disabled={
              comboFiltersPicked[1].length === 0 &&
              (comboFiltersPicked[0].length === 0) &
                (comboFiltersPicked[1].length === 0)
            }
            className={classes.searchBtn}
            style={{
              marginRight: "auto",
              marginLeft: "10px",
            }}
          >
            <NavLink to="/search" className={classes.navlink}>
              {translations.statics.go}
            </NavLink>
          </Button>
        ) : null}
      </Grid>
    </div>
  );
};

export default Filters;
