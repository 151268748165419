import React, { createContext, useState, useEffect, useContext } from "react";
import { APIContext } from "./APIContext";
export const SearchContext = createContext();

const SearchContextProvider = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false, errorMessage: "" });

  const [filterData, setFilterData] = useState([]);
  const [govAreaMappings, setGovAreaMappings] = useState({});
  const [comboFiltersPicked, setComboFiltersPicked] = useState([
    [],
    [194, 195, 196],
    [],
  ]);

  const [searchResults, setSearchResults] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);

  const { getFilters, searchHandler } = useContext(APIContext);

  useEffect(() => {
    getFilters(setFilterData, setGovAreaMappings, setError);
  }, []);

  useEffect(() => {
    let areas = [];
    if (filterData.length === 0) {
      return;
    }
    comboFiltersPicked[1].forEach((pickedId) => {
      if (govAreaMappings[pickedId]) {
        areas.push(...govAreaMappings[pickedId]);
      }
    });
    let newData = [...filterData];
    newData[2].options = areas;
    setFilterData(newData);
  }, [comboFiltersPicked]);

  const handleSearch = (pageNumber, newCombo) => {
    setLoading(true);
    if (!pageNumber) {
      pageNumber = currPage;
    }

    let currCombo = [...comboFiltersPicked];

    if (newCombo) {
      currCombo = newCombo;
    }

    let finalIds = [];

    if (currCombo[2].length > 0) {
      finalIds = currCombo[2];
    } else {
      finalIds = currCombo[1];
    }

    searchHandler(finalIds, currCombo[0], searchInput, pageNumber)
      .then((results) => {
        setPagesCount(parseInt(results.totalPages));
        setSearchResults(results.places);
        setError({ isError: false });
        setLoading(false);
      })
      .catch((error) => {
        setError({ isError: true, errorMessage: "NetworkError" });
      });
  };
  return (
    <SearchContext.Provider
      value={{
        searchInput,
        setSearchInput,
        comboFiltersPicked,
        setComboFiltersPicked,
        filterData,
        setFilterData,
        govAreaMappings,
        setGovAreaMappings,
        handleSearch,
        currPage,
        setCurrPage,
        searchResults,
        loading,
        pagesCount,
        error,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export { SearchContextProvider };
