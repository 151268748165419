import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import guide from "../../assets/icons/guide.svg";
import search from "../../assets/icons/search.svg";

import LabeledIcon from "./LabeledIcon";
import { NavLink } from "react-router-dom";
import { translations } from "../../helpers/Translations";

const useStyles = makeStyles(() => ({
  navigation: {
    display: "inline-flex",
    alignItems: "center",
    height: "70px",
    marginRight: "25px",
  },
  flag: {
    width: "100%",
    height: "auto",
  },
  link: {
    width: "40px",
    height: "40px",
    margin: "0px 10px",
  },
}));
const Navigation = () => {
  const classes = useStyles();

  return (
    <div className={classes.navigation}>
      <a
        className={classes.link}
        href="https://salametna.com/"
        title="Salametna Egypt"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="shamseya.org"
          className={classes.flag}
          src={require("../../assets/egypt_flag.png")}
        />
      </a>

      <NavLink to="/" className={classes.link}>
        <img
          alt="shamseya.org"
          className={classes.flag}
          src={require("../../assets/tunisia_flag.svg")}
        />
      </NavLink>

      {/* Guide */}
      <LabeledIcon
        icon={guide}
        label={translations.statics.guide}
        to="/guide"
      />
      {/* Search */}
      <LabeledIcon
        icon={search}
        label={translations.statics.search}
        to="/search"
      />
    </div>
  );
};

export default Navigation;
