import React, { useContext } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import RemoveChoice from "@material-ui/icons/RemoveCircle";
import AddChoice from "@material-ui/icons/AddCircleOutline";
import { SearchContext } from "../../contexts/SearchContext";
import { translations } from "../../helpers/Translations";

const useStyles = makeStyles((theme) => ({
  body: {
    minWidth: "300px",
    height: "100%",
  },
  checkCircle: {
    color: COLORS.secondaryYellow,
  },
  formLabelWidth: {
    width: "200px",
  },
  checboxWidth: {
    width: "50px",
  },

  zeroMargin: {
    margin: "0px",
  },
  option: {
    fontFamily: "Frutiger-Normal",
    margin: "0px",
  },
}));

const FilterExpansion = (props) => {
  const classes = useStyles();
  const {
    comboFiltersPicked,
    setComboFiltersPicked,
    govAreaMappings,
    handleSearch,
  } = useContext(SearchContext);

  const filterClick = (id, row) => {
    let newCombo = [...comboFiltersPicked];
    if (newCombo[row].includes(id)) {
      newCombo[row] = newCombo[row].filter((record) => record !== id);
      if (row === 1) {
        let removedGovAreas = govAreaMappings[id];
        let newPickedAreas = [...newCombo[2]];

        newCombo[2].forEach((areaPicked) => {
          removedGovAreas.forEach((removedArea) => {
            if (removedArea.id === areaPicked) {
              newPickedAreas = newPickedAreas.filter(
                (area) => area !== areaPicked
              );
            }
          });
        });
        newCombo[2] = newPickedAreas;
      }
    } else {
      newCombo[row].push(id);
    }
    setComboFiltersPicked(newCombo);
    handleSearch(1, newCombo);
  };

  return (
    <Accordion
      style={{
        marginBottom: "20px",
        fontSize: "18px",
        borderRadius: "10px",
      }}
    >
      <AccordionSummary
        className={classes.zeroMargin}
        expandIcon={<ExpandMoreIcon />}
      >
        <p
          className={classes.zeroMargin}
          style={{ fontSize: "16px", fontWeight: "900" }}
        >
          {props.data.title}
        </p>
      </AccordionSummary>
      <AccordionDetails
        style={{
          maxHeight: "190px",
          overflow: "auto",
          overflowX: "hidden",
          fontFamily: "Frutiger-Normal",
        }}
      >
        <div>
          {props.data.options.map((filter) => (
            <FormControlLabel
              key={filter.name}
              className={classes.formLabelWidth}
              control={
                <div className={classes.checkboxWidth}>
                  <Checkbox
                    icon={<AddChoice className={classes.checkCircle} />}
                    checkedIcon={
                      <RemoveChoice className={classes.checkCircle} />
                    }
                    checked={
                      comboFiltersPicked &&
                      comboFiltersPicked[props.i].includes(filter.id)
                    }
                    onChange={(e) => {
                      filterClick(filter.id, props.i);
                    }}
                  />
                </div>
              }
              label={
                <p className={classes.option}>
                  {translations[filter.name]
                    ? translations.translations[filter.name]
                    : filter.name}
                </p>
              }
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterExpansion;
