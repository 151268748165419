import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FilterExpansion from "./FilterExpansion";
import { SearchContext } from "../../contexts/SearchContext";

const useStyles = makeStyles(theme => ({
  body: {
    width: "80%",
    height: "100%"
  }
}));

const SearchResultsSideBar = () => {
  const classes = useStyles();

  const { filterData } = useContext(SearchContext);

  return (
    <div className={classes.body}>
      {filterData.map((row, index) => (
        <FilterExpansion i={index} key={index} data={row} />
      ))}
    </div>
  );
};

export default SearchResultsSideBar;
