import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  socialNetworkIcon: {
    width: "100%",
    height: "100%",
  },
  link: {
    width: "60px",
    height: "auto",
    maxHeight: "70px",
  },
}));

const SocialMedia = () => {
  const classes = useStyles();
  let propsStyle = { justifyContent: "center" };
  propsStyle.width = "100%";
  propsStyle.direction = "rtl";
  propsStyle.display = "inline-flex";
  propsStyle.marginTop = "5px";

  return (
    <div style={propsStyle}>
      <a
        className={classes.link}
        href="https://shamseya.org/"
        title="Shamseya"
        style={{ marginRight: "10px" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="shamseya.org"
          src={require("../../assets/shamseya.svg")}
          className={classes.socialNetworkIcon}
        />
      </a>
      <a
        className={classes.link}
        href="https://www.international-alert.org/where-we-work/tunisia"
        title="International Alert"
        style={{ marginRight: "10px" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="International Alert"
          src={require("../../assets/international-alert.png")}
          className={classes.socialNetworkIcon}
        />
      </a>
    </div>
  );
};

export default SocialMedia;
