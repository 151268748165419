import React, { useContext } from "react";
import { COLORS } from "../../helpers/Colors";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Grid, Tooltip } from "@material-ui/core";

import Star from "../../assets/new icons/star.svg";
import StarFilled from "../../assets/new icons/starFilled.svg";

const useStyles = makeStyles((theme) => ({
  placeName: {
    textAlign: "center",
    fontWeight: "900",
    fontSize: "22px",
    marginBottom: "0px",
    marginTop: "0px",
  },
  address: {
    margin: "0px",
  },
  chipLabel: {
    background: COLORS.secondaryYellow,
    color: COLORS.white,
    marginTop: "15px",
    fontFamily: "Frutiger-Normal",
    textTransform: "capitalize",
  },
  badge: {
    maxWidth: "50px",
    width: "20%",
  },
  star: {
    width: "40px",
  },
  feature: {
    width: "50px",
  },
  section: {
    width: "100%",
    marginTop: "10px",
  },
}));
const cleanBadge = require("../../assets/new icons/Badge-clean.svg");
const safeBadge = require("../../assets/new icons/Badge-safe.svg");
const contactLess = require("../../assets/new icons/Feature-contactless-icon.svg");
const outdoors = require("../../assets/new icons/Feature-outdoor-icon.svg");
const driveThrough = require("../../assets/new icons/Feature-drivethrough-icon.svg");

const PlaceName = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{
        textAlign: "center",
        padding: "20px 0px",
        maxWidth: "320px",
      }}
    >
      <Grid container justify="center" alignItems="center">
        {/* Place Name */}
        <h2 className={classes.placeName}>{data["place_name"]}</h2>
        {/* Stars for Score */}
        <div style={{ margin: "0px 10px" }}>
          <img
            src={parseInt(data.score) >= 1 ? StarFilled : Star}
            className={classes.star}
          />
          <img
            src={parseInt(data.score) >= 2 ? StarFilled : Star}
            className={classes.star}
          />
          <img
            src={parseInt(data.score) >= 3 ? StarFilled : Star}
            className={classes.star}
          />
        </div>
        <p className={classes.address}>{data[`address`]}</p>
      </Grid>
      {/* Place type */}
      <Chip
        className={classes.chipLabel}
        size="medium"
        label={data[`type_select_tag`][0]}
      />
      {/* Badges */}
      <div className={classes.section}>
        {data.clean && (
          <Tooltip title="Clean Badge">
            <img className={classes.badge} src={cleanBadge} alt="Clean Bade" />
          </Tooltip>
        )}
        {data.safe && (
          <Tooltip title="Safe Badge">
            <img className={classes.badge} src={safeBadge} alt="Safe Badge" />
          </Tooltip>
        )}
      </div>
      {/* Features */}
      <div className={classes.section}>
        {data.outdoor && (
          <Tooltip title="Outdoors Feature">
            <img className={classes.feature} src={outdoors} alt="outdoors" />
          </Tooltip>
        )}
        {data.drivethrough && (
          <Tooltip title="Drive Through Feature">
            <img
              className={classes.feature}
              src={driveThrough}
              alt="drive-through"
            />
          </Tooltip>
        )}
        {data.contactless && (
          <Tooltip title="Contactless Feature">
            <img
              className={classes.feature}
              src={contactLess}
              alt="contact-less"
            />
          </Tooltip>
        )}
      </div>
    </Grid>
  );
};

export default PlaceName;
