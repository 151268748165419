import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import burger_icon from "../../assets/icons/burger_icon.png";
import { translations } from "../../helpers/Translations";
import IconMenu from "./IconMenu";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  navigation: {
    display: "inline-flex",
    alignItems: "center",
    height: "70px",
  },
  flag: {
    width: "100%",
    height: "auto",
    margin: "5px",
  },
  link: {
    width: "30px",
    height: "30px",
    margin: "2px",
  },
}));
const MobNavigation = () => {
  const classes = useStyles();

  const menuLabels = [
    { name: translations.statics.guide, to: "/guide" },
    { name: translations.statics.search, to: "/search" },
  ];

  return (
    <div className={classes.navigation}>
      <a
        className={classes.link}
        href="https://salametna.com/"
        title="Salametna Egypt"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="shamseya.org"
          src={require("../../assets/shamseya.svg")}
          className={classes.flag}
          src={require("../../assets/egypt_flag.png")}
        />
      </a>
      <NavLink to="/" className={classes.link}>
        <img
          alt="shamseya.org"
          className={classes.flag}
          src={require("../../assets/tunisia_flag.svg")}
        />
      </NavLink>
      <IconMenu items={menuLabels} icon={burger_icon} />
    </div>
  );
};

export default MobNavigation;
